<template>
  <div class="lpr">
    <!-- <LeftBar class="navbar" /> -->
    <iframe
      :src="'/subscription2#' + path"
      width="100%"
      height="100%"
      frameborder="0"
      ref="subscription2Iframe"
    ></iframe>
    <AllowAudio v-if="showAllowAudio" @closemodal="closeAllowAudioModal" />
  </div>
</template>

<script>
// import LeftBar from '@/components/LeftBar.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'LPR',
  data() {
    return {
      // msgEvent: null
      path: ''
    }
  },
  components: {
    // LeftBar,
    AllowAudio: () => import('@/components/AllowAudio.vue')
  },
  computed: {
    ...mapState(['showAllowAudio', 'userFlatObj'])
  },
  watch: {
    'userFlatObj.language'() {
      this.sendLocaleChangedMessage()
    }
  },
  beforeRouteEnter(to, _from, next) {
    next((vm) => {
      vm.path = to.params.pathMatch ?? ''
    })
  },
  methods: {
    ...mapMutations('history', ['updateShowExportPanel']),
    closeAllowAudioModal() {
      this.$store.commit('updateShowAllowAudio', false) // 之後不需再顯示跳窗詢問
    },
    sendLocaleChangedMessage() {
      this.$refs.subscription2Iframe.contentWindow.postMessage({
        event: 'localeChanged',
        data: {
          locale: this.userFlatObj.language
        }
      })
    },
    msgEventFunc(e) {
      const { event, data } = e.data

      // 安全性處理：https://developer.mozilla.org/zh-CN/docs/Web/API/Window/postMessage#%E5%AE%89%E5%85%A8%E9%97%AE%E9%A2%98
      if (e.origin !== location.origin) return

      switch (event) {
        // The app is ready
        case 'ready':
          this.sendLocaleChangedMessage()
          break
        // 銜接 車號管理 匯出 報表
        case 'reportCreated':
          this.updateShowExportPanel(true)
          break
        // Reflect on the iframe URL changes
        case 'pathChanged':
          this.$router.replace('/lpr_recognition' + data.path)
          break
        // The user has been expelled from the iframe
        case 'expelled':
          this.$router.push('/login')
          break
      }
    }
  },
  mounted() {
    window.addEventListener('message', this.msgEventFunc)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.msgEventFunc)
  }
}
</script>

<style lang="scss" scoped>
.lpr {
  display: flex;
  width: 100vw;
  height: 100vh;

  background-color: $color_151B35;
}

/* .navbar {
  width: 50px;
  height: 100%;
} */
</style>
